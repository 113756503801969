._3265aqeVhKPD6xMVMltyfI {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
._3OmhbjIyqMlpYzLwB7pltz {
  display: flex;
}
._3C35dEVETeUBdLXxK_NmNd {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}
._2FdO1Qx7-P3jYPWAfJ599e {
  min-width: 32px;
  height: 32px;
  flex: 0 0 32px;
  margin: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 32px;
  color: #202325;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 2px;
}
._35r1tYU6tcTtuqm4LbQkLq {
  min-width: 32px;
  height: 32px;
  flex: 0 0 32px;
  margin: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 32px;
  color: #202325;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 2px;
  color: white;
  background-color: #ea5f00;
  border-color: #ea5f00;
}
.WMO2OW6R3_ZGDmw5NvF1I {
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.WMO2OW6R3_ZGDmw5NvF1I:hover {
  border-color: #ea5f00;
  cursor: pointer;
}
.WMO2OW6R3_ZGDmw5NvF1I:hover ._17XOWLg73BHaZ8eA84wiI5 path {
  fill: #ea5f00;
}
._24kMwlcg5Bzl6zUzmlZugY {
  margin-right: 4px;
}
._14-VLFvgK7LLnzxRMLrrfQ {
  margin-left: 4px;
}
._17XOWLg73BHaZ8eA84wiI5 {
  width: 7px;
  height: 10.5px;
  shape-rendering: geometricPrecision;
}
._17XOWLg73BHaZ8eA84wiI5 path {
  fill: rgba(0, 0, 0, 0.87);
}
.WMO2OW6R3_ZGDmw5NvF1I._2Vwn8GyYT2lzrOKMNvEwa4 {
  border-color: rgba(0, 0, 0, 0.09);
  cursor: default;
}
.WMO2OW6R3_ZGDmw5NvF1I._2Vwn8GyYT2lzrOKMNvEwa4 ._17XOWLg73BHaZ8eA84wiI5 path {
  fill: rgba(0, 0, 0, 0.09);
}
